@require 'variables'

.footer-container
    background-color nav-bg-color
    color ncss-white
    padding 0.7rem 0
    a
        color ncss-white

.footer-copyright
    display block
    float right

.footer-margin-adjust
    margin-top: 0px
    margin-bottom: 0px

.vertical-line
    border-left 3px  solid ncss-white
    margin: 15px
      
.footer-hr
    border-color ncss-grey
    
.page-align-items
   flex 1 0 auto
   margin 60px 80px

.page-align-fixed-items 
     overflow: hidden;
     padding: 0px 0px 0px 0px !important  

.version-styl
    color: #808080 !important;
    padding-left: 75% !important;

.menu-logo-footer
    font-size: 8px !important;
    vertical-align: baseline;