@require 'variables'

.dashboard

    .status-area
        vertical-align: top;

    .status-box
        padding: 1.5rem 0;
        position: relative;

        .progress_bar
            position: absolute;
            width: 100%;
            top:0

    .chart-area
       border-left: 1px solid ncss-grey;
       padding: 0 5rem;
       display: none

    .p0-top-sm
        padding: 0 1px 30px 1px

    .m0-sm
        margin 0   
        box-shadow: 5px 5px 5px #b1acac;
        border: 1px solid #cacfd2;

    .lab
        margin-left: -36px
        margin-right: -34px
    
    .nike-icon
        font-size 25px

    .converse-icon
        font-size 15px
        
    .hurley-icon
        font-size 18px
        
    .save-history-btn
        float: right;
        font-weight: 700;
        background:rgba(52,152,219,.9);
        color: #ffffff;
        font-size: 12px;
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 10px 0px 10px;

    .time-duration-btn
        float: left;
        font-weight: 700;
        color:black;
        font-size: 12px;
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 5px 0px 5px;
        cursor: pointer


    .time-duration-btn-text
        float: left;
        font-weight: 700;
        color:black;
        font-size: 12px;
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 5px 0px 5px;

    .active-time-duration-btn
        float: left;
        font-weight: 700;
        background:rgba(52,152,219,.9);
        color: #ffffff;
        font-size: 12px;
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer

    .background-status
        padding: 20px 0 20px 0;;
        background: #ddd
        padding: 10px 20px 10px 20px;
        background: rgba(221, 221, 221, .5);
        margin: 10px;
        border-radius: 20px;
        box-shadow: 5px 5px 5px #b1acac;

    .background-status:hover
        background: rgba(153, 153, 153, .7)
        color: white
        cursor: pointer;
    
    
    .save-history-btn:disabled
        float: right;
        font-weight: 700;
        border 1px solid #999999
        background-color #cccccc
        color #666666
        font-size: 12px;
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 10px 0px 10px;
        cursor: not-allowed;
        pointer-events: none;

    .filter-class
        padding: 5px 0 4px 0;
        background: #ddd;
       
    .ag-side-bar .ag-side-buttons div button span
        color:rgba(52,152,219,1);
        font-weight: bold;
        font-size: 13px;

    .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-side-button button
        border-color: #dddddd

    .ag-body-viewport-wrapper.ag-layout-normal {
        // overflow-x: scroll;
            }
     ::-webkit-scrollbar {
        -webkit-appearance: none;
        margin-top:3px
        width: 6px;
        background:  rgba(0,0,0,.1);
        // height: 6px;
        }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: rgba(0,0,0,.3);
        box-shadow: 0 0 1px rgba(255,255,255,.5);
        }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
         /* IE10+ CSS */
        .ag-side-bar .ag-side-buttons button{

          float: right 

        }
        .ag-side-bar .ag-side-buttons div button .ag-icon-columns{
            position relative;
            right -16px
            top -3px

        }
        .ag-side-bar .ag-side-buttons div button .ag-icon-filter{
            position relative;
            right -7px
            top -5px

        }

        .ag-body-viewport-wrapper.ag-layout-normal {
            overflow-x: hidden !important;
        }

    }    

    @supports (-ms-ime-align:auto) and (font-variation-settings:normal) { 
        /*EDGE 17 */
        .ag-body-viewport-wrapper.ag-layout-normal {
            overflow-x: hidden !important;
        }
        }
.ag-header-cell-resize
    border-left: 6px solid #C0C0C0 !important;
    width: 5px !important;
    height:21px !important
