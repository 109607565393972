.submit-button-frf
    padding 0.4rem 2.5rem
    float right
    border-style double
    border-width thin
    border-radius 0.3rem
    border-color darkgray

.upload-sec
    float right
    margin-bottom: 10px
    margin-right 10px
    i
        color #ccc
        padding-right 5px 
        font-size 22px
.upload-frf
    background ncss-white
    font-weight bold
  
    letter-spacing 0
   
    &:focus
        outline none


.download-print-button-FRF
        color: #0078b9;
        font-weight bold
        padding 0
        background: #f7f7f7
        letter-spacing 0
        font-family unset
        font-size 14px
        &:focus
            outline none

.attachment-set
    line-height: 42px;
    padding-bottom: 0;

.attachment-sets
    padding-bottom: 0;

.download_button 
    color: #333;
    padding-right: 5px;
    font-size: 25px;
    padding: 0px 2px;
    margin-left: 10px;
.download_button:hover 
    background-color: #ccc;
    color: white;
    cursor: pointer; 
.frf-attachment-align
    padding-left: 15px;
    vertical-align: top;
    