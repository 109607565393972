.switch 
  position: relative;
  display: inline-block;
  width: 44px;
  height: 18px;
  margin-top : 4px;


.switch input 
  opacity: 0;
  width: 0;
  height: 0;


.slider 
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color  ncss-error
  -webkit-transition: .4s;
  transition: .4s;


.slider:before 
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 1px;
  right : 1px;
  bottom: 1px;
  top: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;


input:checked + .slider 
  background-color: ncss-success;


input:focus + .slider 
  box-shadow: 0 0 1px ncss-success;


input:checked + .slider:before 
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);


/* Rounded sliders */
.slider.round 
  border-radius: 20px;


.slider.round:before 
  border-radius: 50%;
