  .Collapsible
    vertical-align: top;
    background: #e5e5e5;
    padding: 0.5rem 2rem;
    margin-top: 8px;
    margin-bottom: 10px;
    border: 2px solid #ddd;
  .Collapsible__trigger
    width: 100%
    position: relative;
    cursor: ns-resize
    // font-weight: bold
    &.is-closed {
      &:after {
        transform: rotateZ(180deg);
      }
    }

  .collapsible_IndividualTest_container
    width: 98%;  
