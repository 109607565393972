.add-supplier-location-buttton
    width auto
    border: 0px

.add-supplier-search 
    width 11rem !important

.add-supplier-search-color
   background: #111;

.full-width
    width 100%    

.supplier-add-new-button
    background-color: #fff
    background: #ddd;
    border: 1px solid #ddd;

.active-supplier-button  
   color: #0078b9;
   background: #fff;
   border-bottom: 5px solid #0078b9;

.not-active-supplier-button  
    color: #000000  
    border: 1px solid #ddd;
    color: #000;
    background: #ddd;
    opacity: .5

.extra-bottom-margin
    margin-bottom: 100px

.adjust-padding
    padding-left 75px !important

.adjust-location-text-padding    
    padding-left: 50px

.span_addsupplier
    height:1vh

.supplier_class
    padding: 0;
    padding-bottom: 18px;

.supplier_class-tab
    padding: 0;
    padding-bottom: 0px;


.dropdown-add-supplier .select-input-font,
.dropdown-add-locations .select-input-font
    font-size: 12px !important;
    font-weight: bold !important;

.mandatory_supplier
    color: red;

.update-exception-buttton
    width 15rem
    border: 0px

.update-notification-buttton
    border: 0px
    padding: 0.45rem 2.4rem;

.edit-user-modal-container
    width 50% !important
    top 40% !important
    max-height 80%
    overflow auto

.add-supplier-modal-container
     width 80% !important
    top 40% !important

.grid-filter-textbox-licensee
    border: 2px solid #3498db;
    width: 100%;
    height: 2.5rem;
    padding: 15px;
    color: black;
    font-size: 13px;
    font-weight: bold;

.grid-height-adjust
    height 18rem

.close-btn
  margin-right: 65px;
  margin-top: 3px
  
.info-box
  margin-left: 7px