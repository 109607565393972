 .modal-container
    position absolute
    top 40%
    left 50%
    right auto
    bottom auto
    background-color #ffffff
    margin 0 auto
    transform translate(-50%, -50%)
    padding 10px 20px
    width 40%
    border 5px solid #3498db !important;

  .comment-modal-container
    position absolute
    top 0
    right 0
    bottom 0
    background-color #ffffff
    padding 10px 20px
    width 40%
    height 92.7%
    border 3px solid #3498db !important;

  .modal-overlay
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba(0,0,0,0.5)
    z-index: 11

  .modal-close-btn
    background transparent
    margin 5px -15px 0 0
    float right

  .close
    margin-bottom: -7px;

  .modal-text
    font-size 18px
    color black
    font-weight bold
    word-break: break-word

  .notify-modal
    width: 80%;
    min-height: 30%;
    max-height: 75%
    overflow-x: hidden
    white-space pre-line