.header-container
    padding 5px 5px 5px 5px
    margin 0
    position fixed
    z-index: 11
    width: 100%
    border-bottom: 2px solid #dddddd

.hide-header
    z-index: 0

.inline-display
    display inline-block    

.relative-position
    position relative
    
.header-setting-button
    font-size: 20px 
    background-color ncss-white

.header-brand
    left 3rem  
    top 0rem 

.header-name-padding
    padding 7px  

.menu-icons
    width 7%
    
.top-align
    float: right;
    padding-right: 30px; 
    font-size: 15px;
    color: #000
    @media screen and (max-width 1024px)
        padding-right: 2rem; 

.header-name
    float right

    ///Notification Section

.notification-popover-section
    
    border: 1px solid #ddd!important;
    box-shadow: 5px 5px 5px #b1acac;
    background-color: rgb(251, 252, 252 );
    overflow-y: scroll;
    height: 400px;

.custom-notification-section
    text-align: left;
    padding: 4px 2px 2px 5px;
    border: 1px solid #b1acac;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

.notification-tiny-popover 
    width: 100% !important;
    left: 0% !important;
    height: 85%;
    background: rgba(0,0,0,0.2);
    border: 2px solid #ddd;
    padding-left: 13%;
    padding-right: 15%;
    
.link-text
    background: rgba(52,152,219,0.9);
    cursor: pointer;
    /* text-decoration: underline; */
    font-weight: 600;
    color: white;
    font-weight: 600  
    font-size: 12px;
    text-align: center;
    

.link-text-close
    background: rgba(52,152,219,0.9);
    cursor: pointer;
    /* text-decoration: underline; */
    font-weight: 600;
    color: white;
    font-weight: 600  
    padding: 2px 9px 3px 8px;
    font-size: 11px;

.notification-head
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border: 1px solid #b1acac;
    color: #ffffff;
    background: ncss-error;
    padding-top: 6px;
    padding-bottom: 6px;
   

.button-notification
    padding-right: 10px


.notification-message
    font-weight: 600 

.text-notify
    padding-left: 10px

.bell-style
    color: ncss-error
    font-size: 18px;

.bell-style-inactive
    color: #000000
    font-size: 18px;

.text-notify-no
    padding-left: 10px;
    font-size: 20px;
    padding: 15px;
    text-align: center
    color rgb(185, 185, 185)

.unread-line
     border-left: 1px solid #3498db;
     background: rgba(175,175,175,.4);    

.notification-link
    color ncss-link-color
    text-decoration underline     