@require 'variables'

ol.progtrckr 
  list-style-type none
  padding-left 11%
  text-align center
  counter-reset my-sec-counter
  font-weight 900
  font-size 1.5rem
  z-index 11
  @media screen and (max-width: 1024px) and (min-width: 771px)
    padding-left 3rem
  @media screen and (max-width: 770px)  
    text-align: left
    padding-left 3rem

.stepper
  width 100%
  position absolute
  top 50px
  right 0
  bottom 0
  left 0
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display flex
  flex-direction: column


.progtrckr-steps
  height 10rem
  width: 100%
  margin: 1rem auto 0 auto
  overflow: visible
  background-color: #f7f7f7
  flex: 1
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
  flex-direction: column
  align-items: center;
  

.progtrckr-component
    padding: 1rem 4.5rem 8rem 4.5rem
    flex: 1
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex
    overflow: hidden
    flex-direction: column
    background: #f7f7f7

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)   
  .progtrckr-steps
    height 10rem
    z-index: 3 !important
    position: fixed
    width: 100%
    margin: 0 auto
    overflow: visible
    background-color: #f7f7f7 !important
    margin-bottom: 10rem !important

  .stepper
    padding-bottom: 130px !important
    top: 24px !important

  .progtrckr-component
    padding: 1rem 4.5rem 0.5rem 4.5rem
    position relative  
    top 10rem
    background: #f7f7f7

@supports (-ms-ime-align:auto) 
  .progtrckr-component 
    padding: 1rem 4.5rem 0.5rem 4.5rem;
    
    

ol.progtrckr li 
  display inline-block
  text-align center
  line-height 4.5rem
  cursor: pointer


ol.progtrckr li span 
  padding 0 0.2rem
  position relative
  top 5rem
  left -7.3rem
  font-size 14px
  display -moz-inline-grid
  display -ms-inline-grid
  display inline-grid
  -ms-grid-columns: 150px 20px auto 20px 200px;    
  -ms-grid-rows: auto 1fr; 
  line-height 1.4rem
  width 11rem
  height 1rem
  pointer-events:none
  @media screen and (max-width: 1024px) and (min-width: 771px)
    left -2.3rem
    width 5rem
    pointer-events:none
  @media screen and (max-width: 1024px) 
    left -4.3rem
    width 4rem
    pointer-events:none

@media (max-width: 650px) 
  .progtrckr li span 
    display none
  

.progtrckr em 
  display none
  font-weight 700
  padding-left 1rem


@media (max-width: 650px) 
  .progtrckr em 
    display: inline

ol.progtrckr li.progtrckr-todo 
  color ncss-grey
  border-bottom 4px solid ncss-grey-light


ol.progtrckr li.progtrckr-todo:last-child
   border-bottom 0px
   @media (max-width: 770px)  
    width 0

ol.progtrckr li.progtrckr-doing
  color ncss-accent
  border-bottom 4px solid #CCCCCC


ol.progtrckr li.progtrckr-doing:last-child
   border-bottom 0px
   @media (max-width: 770px)  
    width 0

ol.progtrckr li.progtrckr-done 
  color ncss-grey
  border-bottom: 4px solid ncss-accent

// Code for adding Icons in progress tracker instead of numbers will remove 
ol.progtrckr li:after {
 // content: "\00a0\00a0";
}

ol.progtrckr li:before 
  position relative
  bottom -2.9rem
  counter-increment my-sec-counter
  content counter(my-sec-counter)
  float left
  left -1%
  width 3.5rem
  border 1px solid ncss-grey


ol.progtrckr li.progtrckr-todo:before 
  color silver
  background-color white
  padding 10px
  line-height 1.4em
  border-radius 50% 

ol.progtrckr li.progtrckr-todo:hover:before 
  color #34495E  


ol.progtrckr li.progtrckr-doing:before 
  color white
  background-color ncss-accent
  border 1px solid ncss-accent
  padding 10px
  line-height 1.2em
  border-radius 50%



ol.progtrckr li.progtrckr-doing:hover:before 
  color #34495E  


ol.progtrckr li.progtrckr-todo:last-child
   border-bottom 0px


ol.progtrckr li.progtrckr-done:before 
  // content: "\2713";
  color white
  background-color  ncss-grey
  padding 10px
  line-height 1.2em
  border-radius 50%

.ol.progtrckr li.progtrckr-error:before 
  // content: "\2713";
  color white
  background-color  ncss-error !important
  padding 10px
  line-height 1.2em
  border-radius 50% 

.ol.progtrckr li.progtrckr-error
  color ncss-grey
  border-bottom: 4px solid ncss-accent  

.stepper-error-doing:before 
  // content: "\2713";
  background-color  ncss-orange !important

.stepper-error-doing
  color ncss-grey
  border-bottom: 4px solid ncss-accent  

.stepper-error-done:before 
  // content: "\2713";
  background-color #F0B27A !important

.stepper-completed:before
  color white !important
  background-color ncss-grey !important  

.stepper-error-done
  color ncss-grey
  border-bottom: 4px solid ncss-accent    

.stepper-error-done
  color ncss-grey
  border-bottom: 4px solid ncss-accent 

.stepper-completed
  color white
  border-bottom: 1px solid ncss-accent      

.navigation-buttons
  padding 0.01rem

.previous-button
  width 10%
  position absolute
  top 3.5rem
  left 6.5rem
  border-radius 50%
  font-size: 40px
  height 60px
  background-color ncss-white
  color ncss-grey

.previous-button:hover
  color ncss-accent

.next-button  
  width 10%
  position fixed
  bottom 5.8rem
  right 6.5rem
  z-index 15
  border-radius 50%
  font-size: 40px
  height 60px
  background-color ncss-white
  color ncss-grey

.next-button:hover
  color ncss-accent

ol.progtrckr li.progtrckr-done:hover:before 
  color #333


.step-progress
    margin: 1rem 0rem

.disabled 
    pointer-events:none; 

