@require 'variables'

nav-button-padding = 56px


.menu-container
  display: flex;
  width: 100%;
  height: 100%;

.menu-lightbox
  opacity: 0;
  visibility: hidden;
  background: rgba(0,0,0,0);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  transition: opacity 300ms ease;

.show-menu .menu-lightbox
  opacity: 1;
  visibility: visible;
  background: rgba(0,0,0,0.4);

.menu-logo
  font-size: 22px
  vertical-align: baseline
  padding-right: 10px;

.menu-aside 
  // margin-top: 3rem
  display: flex;
  flex-direction: column
  position: fixed;
  left: 0;
  width: 300px;
  height: 100%;
  z-index: 10001;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 300ms ease;
  
  .nav
    width: 100%;
    height: auto;
    flex 0 0 auto
    overflow-y: auto;
    padding-bottom: 120px;
    background: #ffffff;


  .footer
    flex 1 0 auto
    display flex
    flex-direction: column
    align-items: center

    .footer-spacer
      display block
      flex 1 1 0
    
    span
      margin 0 auto
      flex 0 0 auto
      display flex

.show-menu .menu-aside 
  transform: translateX(0);
  background:rgb(247,247,247);

.viewport
  // padding-left: nav-button-padding
  display: flex;
  flex-direction:  column
  will-change: transform
  width: 100%;
  height: 100%;
  // position: relative; // I dont remember why this got added, but it breaks fullscreen editor
  overflow: auto;
  background: #f7f7f7

.menu-btn
  position: fixed;
  top: 8px;
  left: 8px;

// @media only screen and (min-width: min-break-xl) {
//   .menu-btn {
//       display: none;
//   }
// }

.profile-nav
  position relative
  display flex
  flex-direction row
  align-items center

  .profile-nav-item
    flex 0 0 auto
    vertical-align middle
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    min-width 0
    span
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      min-width 0

  &.profile-nav-item
    flex 1 1 auto
    
  .user-info
    flex 1 1 auto


.badge-notification
  position absolute
  right -8px
  top -8px
  font-size 1rem
  background-color red
  color ncss-white
  display inline
  line-height .8rem
  padding 3px 5px

.menu-header
  text-align left
  padding: 20px;
  background: rgb(247, 247, 247);
  padding-left: 40px;
  border-bottom: 2px solid #ddd;

.seperation-line
  color ncss-grey-light
  margin 25px 0px 25px 0px 

.li-bg-color
  padding: 10px;
  font-family: "Open Sans Condensed", sans-serif;

.menu-icons-nav
    width 7%
    padding: 15px