@require 'variables'

.rc-tooltip-inner {
  background-color: #222;
}

.rc-tooltip {
  font-size: 12px;
  max-width: 40%
}

.tip
  position absolute
  width 100px
  color #ffffff
  background ncss-grey
  height 30px
  line-height 30px
  text-align center
  visibility hidden
  font-weight 900
  border-radius 15px
  
.next-button:hover + .tip-next, .tip-next:hover 
    visibility visible
    opacity 0.8
    top 6.5rem
    right 4.5rem
    z-index 999 

.previous-button:hover + .tip-previous, .tip-previous:hover 
    visibility visible
    opacity 0.8
    top 6.5rem
    left 4.5rem
    z-index 999 

.tooltip 
  position: relative;
  display: inline-block;


.tooltip .tooltiptext 
  visibility: hidden;
  background-color: #707070;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;


.tooltip:hover .tooltiptext 
  position: absolute;
  top -35px
  left -35px
  visibility: visible;
   