.p-bottom-qrf
    padding-bottom 25px

.qrf-padding-left
    padding-left 10px

.qrf-padding-zero
    padding-left 0px !important

.p-top-qrf
    padding-top 15px

.normal-text
    font-weight normal !important

.review-padding-left
    padding-left 23px 

.text-size
    font-size 16px

.text-size-notification
    font-size: 16px;
    color: #000;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-left 19px
    text-align: left;

.text-size-notification-date
    text-align: right;

.text-size-new
    font-size: 20px;
    color: black

.text-size-new-carat
    font-size: 24px;
    color: black

.text-size-training
    font-size: 16px;
    color: #3498db;
    font-weight: bold;
    text-align: right;

.user-padding-empty
    padding-left 0px

.p-top-user
    padding-top 9px

.p-left-user
    padding-left 2px !important

.submit-button-qrf
    padding 0.4rem 2.5rem
    position absolute
    right 0
    margin-right 12rem
    border-style double
    border-width thin
    border-radius 0.3rem
    border-color darkgray

.search-button
    background: #111;
    padding: 0.4rem 2.5rem;
    color: #fff;
    margin-right: 10px;
    margin-left: 20px;

.p2-sm 
    padding 8px
    padding-right 0

.textarea-bordercolor
    border-color #cecece !important
    padding 0.4rem 2.5rem
    border-style double
    border-width thin

.dropdown-border
    border-color #cecece !important
    border-style double !important
    border-width thin !important
    width -webkit-fill-available
    padding 5px 10px

.no-color
    background-color white
.padding-bottom
    padding 20px
    padding-right 0px
    padding-left 0px

.collapse-p0
    padding-left 0
    font-size 1.5rem

.collapse-p0 .ncss-col-sm-1
        font-size 25px


.dropdown-border-location
    border-color: #cecece !important;
    width: -webkit-fill-available;
    padding: 5px 10px;
    font-size: 16px;
    height: 37px;
    background: #fff;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;