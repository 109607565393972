@require 'footer'
@require 'core'
@require 'navbar'
@require 'select'
@require 'ncss'
@require 'forms'
@require 'spinner'
@require 'variables'
@require 'markdown'
@require 'menu'
@require 'collapse'
@require 'alertify'
@require 'tooltip'
@require 'header'
@require 'donut'
@require 'grid'
@require 'dashboard'
@require 'progressTracker'  
@require 'trf'
@require 'modal'  
@require 'qrf'
@require 'testDetail'
@require 'list'
@require 'customTable'
@require 'datePicker'
@require 'frf' 
@require 'addMaterial'
@require 'addSupplier'
@require 'progressBar'
@require 'loader'
@require 'videoPlayer' 
@require 'toggle'
@require 'commentModal'
@require 'multiTags'
@require 'toogleSwitch'
  
html, body, #app-host
  height 100%
  background-color #ffffff
  font-family 'Open Sans Condensed', sans-serif

body 
  -ms-overflow-style: scrollbar;
    
.viewport
  height: 100vh

.footer
  position:fixed
  z-index 5
  bottom 0
  width 100%
  margin-top : 85px

.offline-section
  padding card-padding
  h2
    text-align center
    font-size 4rem
    text-transform uppercase

.text-color-accent 
    color #0078B9

.u-italic, em
    font-style normal
    color #0078b9

.button-top-adjust .u-uppercase
    float right

.test_button
    padding-right: 0px;
    padding-left: 0px;
    padding-top: -1px;
    color: #fff;
    font-size: 13px;
    background: #3498db;
    border-radius: 2px 2px;
    line-height: 22px;
    margin-top: 2px;
    text-align: -webkit-center
    text-align: center;
    font-weight: 700;
    background: rgba(52,152,219,0.9);
    color: #fff;
    font-size: 12px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  
 .test_button_proceed
    padding-right: 0px;
    padding-left: 0px;
    padding-top: -1px;
    color: #fff;
    font-size: 13px;
    background: #3498db;
    border-radius: 2px 2px;
    line-height: 22px;
    margin-top: 2px;
    text-align: -webkit-center
    text-align: center; 
    font-weight: 700;
    background: rgba(52,152,219,0.9);
    color: #fff;
    font-size: 12px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;

.main-heading
    font-size 2.8rem
*:focus
  outline none
.logout-page
  margin-top 6%
  padding-bottom: 20px;

.clear-button-userpage
    background: #3498db;
    padding: 0.31rem 2.5rem;
    border: 1px solid #3498db;
    margin-right: 10px;
    color: #fff;

.user-page
  padding-left: 15px
  font-size: 32px

.pagenotFound
  font-size: 32px;
  margin: 80px;

.usernotfound
    margin: 40px;
    top: 24px;
    margin-right: 0px;
    width: 50%;
    padding: 30px
.user
    padding-top: 60px;
    padding-bottom: 10px


.rc-progress-line 
   height: 11px;
   width: 100%

.form
  overflow-x: auto
  scroll-behavior: smooth

.container-horizontal-scroll
  padding: 7px
  overflow-y: auto
  overflow-x: hidden
  scroll-behavior: smooth

.text-area-container-sm .ncss-textarea
  min-height: 5px

.text-area-container-notification .ncss-textarea
  min-height: 120px

.invisible
  display: none

.padding-0
  padding: 0 

.archive-page-align-items
    flex 1 0 auto
    margin 55px 80px

  .rw-widget-picker
    height 40px
.archive-search-area
  display flex
  height 60px

.archive-search-area-height-adjust
  height 20px

.archive-button
  width 12rem

.archive-export
  float right
  i 
    padding-right: 5px;
    font-size: 18px;
    
.archive-export-caption  
  padding: 0;
  letter-spacing: 0;
  font-family: unset;  
  background:none;
  color: white

.archive-export-button
    font-size: 14px; 
    text-align: right;
    font-weight: 700;
    float: right;
    padding: 4px;
    color: white;
    background: rgba(52,152,219,.9);
    color: #fff;
    font-size: 12px;
    font-family: Nike TG,Helvetica Neue,Helvetica,Arial,sans-serif;
    padding-left: 10px;
    padding-right: 10px;

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
  .footer
    position: sticky
    bottom: 0
    width: 100%

  body
    overflow: hidden 

  .progtrckr-component
    margin-bottom: 10rem 

  ol.progtrckr li span
    // left -5.2rem
    display: -ms-inline-grid !important

  ol.progtrckr
    padding-top:-1rem

  .progtrckr-component
    top 9rem

  .rw-select > *
    height 24px !important

  .inline-flex-display
    display inline-flex !important  
    

  .ie-inline-display
   display inline-flex !important   

  .menu-lightbox
    position relative


  .viewport
    display: -ms-flexbox;
    -ms-flex-direction: column  
    position: absolute

  .pdfobject
    z-index: -2 !important

  .pdfobject-container  
    height 60vh !important

  .training_color .pdfobject-container
    display none 

  .fake-iframe
    display: block !important
    width: 100%  
    z-index: -1  

  .fake-iframe-footer-height
    height 85px

  .header-container
    top 0  

  .fake-iframe-header-height  
    height 45px

  .page-align-items  
    margin-top 10px 
    //margin-bottom 20px
    margin-left 80px
    margin-right 80px
    padding-bottom 2px

  .extra-bottom-margin  
    margin-bottom 130px

  .download-button-trf  
    margin-bottom: 4%  

  .ie-icon-align
    position relative
    top 9px

  .ie-border-padding-0-fix  
    padding: 0 !important 
    
@supports (-ms-accelerator:true)
  .footer
    position: sticky
    bottom: 0
    width: 100%

  body
    overflow: hidden 

  .progtrckr-component
    margin-bottom: 10rem 

  ol.progtrckr li span
    // left -5.2rem
    display: -ms-inline-grid !important

  ol.progtrckr
    padding-top:2rem

  .progtrckr-component
    top 11rem

  .rw-select > *
    height 24px !important

  .inline-flex-display 
    display inline-flex !important

  .ie-inline-display
   display -ms-inline-flexbox !important  

  .menu-lightbox
    position relative

  .viewport
    display: -ms-flexbox;
    -ms-flex-direction: column  
    position: absolute

@supports (-ms-ime-align:auto) 
  .footer
    position: sticky
    bottom: 0
    width: 100%

  body
    overflow: hidden 

  .progtrckr-component
    margin-bottom: 10rem 

  ol.progtrckr li span
    // left -5.2rem
    display: -ms-inline-grid !important

  ol.progtrckr
    padding-top:2rem

  .progtrckr-component
    top 11rem

  .rw-select > *
    height 24px !important

  .inline-flex-display 
    display inline-flex !important

  .ie-inline-display
   display -ms-inline-flexbox !important  

  .menu-lightbox
    position relative


  .viewport
    display: -ms-flexbox;
    -ms-flex-direction: column  
    position: absolute


  /* loading dots */

.loading-dots:after {
  content: ' .';
  animation: dots 3s steps(1, end) infinite;
}

@keyframes dots {
  0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        text-shadow: .5em 0;
    }
    50% {
        text-shadow: .5em 0, 1em 0;
    }
    62.5% {
        text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    87.5%, 100%{
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
  }

  .training_page
    font-size:20px;
    padding-right:0px;

 .pdf
   width: 100rem
   height: 20rem
  .input-width
    width:100%
  .col-width-adjust  
    width 3.7%;

 .pdf-area
   width: 100%
   overflow-y: hidden
   overflow-x: hidden

 .pdfobject-container
    height: 75vh
    padding-bottom: 10px
    @media screen and (min-height: 1001px)
     height 65vh
.pdf-page-number   
  margin-bottom: 2%
  font-weight: 600

.pdf-page-bottom-align  
  margin-bottom: 10%

.pdf-align-items
   flex 1 0 auto
   margin 55px 80px 80px 80px

.video-align-items
   flex 1 0 auto
   margin 55px 80px 20px 80px


.tab-class
  padding-left:0px !important

.tab-class-bottom
  padding-bottom:0px !important

.tab-class-container
  margin-top: -11px
  margin-left: 0px

.fake-iframe
  display: none

.review_page *
  vertical-align:top
  
.displayFlex
  display flex  
  
.download-button-review
    color: #ccc;
    padding-right: 5px;
    font-size: 30px;
.btn-text
    font-size: 15px;
    margin-top: 9px;
    margin-left: 6px;
    background-color: #f7f7f7;
    font-weight: 300;
    font-weight: bold;
    padding: 0;
    letter-spacing: 0;
    font-family: unset; 
.desc-h4
    margin-bottom:10px;
.desc-span
    margin-left: 6px; 
.text-color-red 
    color: red
.display-flex
    display flex;
    padding 10px;
.commentStyle
  font-size 12px   
  font-family 'nike-glyphs'
.support-link  
  float right
  padding-right 13px
.textAlign
  text-align center;
.pt-pb  
  padding-top: 0px
  padding-bottom: 0px
.download-print-section
        font-size 14px
        background #f7f7f7
        text-align right
        font-weight bold
        float right
        padding-right 17px
        span
            i
                color #ccc
                padding-right 5px 
                font-size 30px  
.download-print-button
        background #f7f7f7
        font-weight bold
        padding 0
        letter-spacing 0
        font-family unset
        &:focus
            outline none
.white-background      
   background #f7f7f7 !important  
.actionUser
    margin-bottom 8px
    text-align  left
    padding-left 10px
    font-size 18px 
.dashedBorder     
    border-top 1px dashed gray
.page-actioncenter
    margin 60px 10px    
.fontFAc
    font-family 'nike-glyphs'   
.checkAC
    color green
    fontSize x-large
    padding-left 10px
.exclamationAc
    color red
    fontSize x-large
    padding-left 10px  
.iconStyle
   display flex 
   justify-content center
   align-Items center 
   font-size 50px 
.displayTextAC
   display flex 
   justify-content center
   align-Items center 
   margin-top 10px 
.mtAC-4  
    margin-top 4%   
.custom-file-upload 
    background-color #f0f0f0
    border 1px solid #ccc
.training-container 
    display: flex;
    flex-direction: column;
.training-header 
    display: flex;
    align-items: center;
.file-row 
    display: flex;
    align-items: center;
.popover-closeButton 
    position: absolute;
    right: 0;
    margin-right:7rem;
    background: transparent;
    color: #ff4500;
.download-fontSize
    font-size: 16px; 
.docview
  margin-bottom 20px
  padding-left 5%
.borderDoc
   border 1px solid #ccc
.docContainer   
  width 100%
  height 100%
  overflow auto
.pageView
    padding 10px
.padRight20
    padding-right: 20px  
.padTop12
    padding-top: 12px
.fontSize14
    font-size: 14px
.fileSizeText
    font-size: 11px;
    font-weight: bold;   

    



     
    
  
          
   