@require 'variables'

html
 background-color background-color

body
 background-color background-color

.link
 //color ncss-link-color
 text-decoration underline

.u-va-tb
 vertical-align: text-bottom

.hidden
 display none

.invisible
 opacity 0

.center
 margin-left auto
 margin-right auto

.pointer-mouse
    cursor: pointer

.relative-container
 position relative

ul.list-unstyled
 list-style-type none

ul.horizontal li
 display inline-block
 list-style-type none


.page-header
 h1.page-title-has-back
   position relative
   margin 1rem 0

   .page-title-back
     position absolute
     bottom -1.5rem
     left 0
     font-size .8rem
     color ncss-accent

 flyout-top-padding = 2px
 .ncss-flyout-btn
   padding flyout-top-padding 40px flyout-top-padding 20px

.page-sub-header

 h1,h2
   padding-top .5rem
   padding-bottom 1rem


.section-header
 .section-header-item
   display inline-block
   vertical-align middle

.u-rotate-right
 transform: rotate(90deg)
.u-rotate-left
 transform: rotate(270deg)

.ncss-dl
 dt
   font-weight:  600
 dd
   padding 0 0 .5rem 0

.ncss-dl-inline
 dt, dd
   display: inline
   margin: 0
 dt
   font-weight:  600
 dd
   padding 0 0 .5rem 0.5rem
 dd:after
   content: '\A'
   white-space: pre


.selectable-highlight
    padding .5rem 1rem
    background-color: ncss-white
    border 1px solid ncss-success
    display:  inline-block
    font-weight: bold
    color ncss-grey
    overflow: auto

.u-nowrap
 white-space: nowrap;

.flex-container
 display flex
 align-items flex-end

 &.start
   align-items start

 &.center
   align-items: center
   justify-content: center

.flex-fill
 padding-top: 10px
 flex 0 0 auto

.flex-fluid
 flex 1 1 auto

.flex-static
 flex 0 0 auto


.u-half-width
   width 50%

.u-half-width-md
 @media only screen and (min-width min-break-md)
   width 50%

.u-half-width-lg
 @media only screen and (min-width min-break-lg)
   width 50%

.u-half-width-xl
 @media only screen and (min-width min-break-xl)
   width 50%

 .u-half-width-xxl
   @media only screen and (min-width min-break-xxl)
     width 50%

.card-container
 padding card-padding
 background-color ncss-white
 box-shadow box-shadow-divider

.circle
  border-radius: 50%
  padding 1px 3px
  
.page-header
h1.page-title-has-back
  position relative
  margin 1rem 0

.rounded
 border-radius 25%

.align-center
 text-align: center

.align-text-right
 text-align: right

.align-left
  text-align left

.big-button
 font-size: 30px
 background-color ncss-white
 border 1px solid ncss-grey

.big-button:focus
  outline:0  

.big-button-selected
 font-size: 30px
 background-color ncss-accent
 border 1px solid ncss-accent
 box-shadow: 5px 5px 5px #b1acac;

.big-button-selected:focus
  outline:0  

.icon-button-margin
 margin: 5px  !important

.button-top-adjust
 position: relative
 top: 0.5rem;

.sub-heading
  font-size: 25px

.sub-header
  font-size: 16px
  color:  #000000
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
  .page-header h1.page-title-has-back
    position relative
    margin 0 0

  .ag-side-bar .ag-side-buttons div button span
    -ms-transform: rotate(90deg)
    margin-left: -15px 
    float right 
    position relative
    right -13px

  .ag-theme-balham .ag-icon-filter
    margin-left: 3px !important
    padding-top: 20px

  .ag-theme-balham .ag-icon-columns  
    margin-left: 3px !important
    padding-top: 35px

  .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-side-button button
    min-height: 100px  

  .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-selected button
    width:100%  

  .dashboard .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-side-button button  
    padding 22px 0