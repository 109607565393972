.sub-heading
    font-size: 25px
    padding-left: 8px

.attachment
    font-size: 25px
    margin-bottom: 0.5rem

.description
    color: ncss-grey

.custom-no-row
 padding: 10px  
 color: red  
 font-size: 15px
 // font-weight: 00

.error_msg
    color: red

.button-trf
  border: 2px solid ncss-grey-md
  border-radius: 4px
  padding: 0.4rem 1rem;
  margin-bottom: 1rem;
  color ncss-grey

.button-lab
  border: 2px solid ncss-grey-md
  border-radius: 4px
  padding: 0.4rem 1.7rem;
  color ncss-grey

.lab-complete
  margin: 178px auto 20px auto;
  padding: 0 90px;

.upload
    float:right;
    margin-right: -72px;
    margin-top: -22px;
    background: #f7f7f7

.download
    margin-top: -22px;
    float:right;
    margin-right:-10px;
    background: none

.download-section
    font-size 14px
    letter-spacing 3px
    background #f7f7f7
    font-weight bold
    i
        color #ccc
        padding-right 5px 
        font-size 30px

.download-button
    background #f7f7f7
    font-weight bold
    padding 0
    letter-spacing 0
    font-family unset
    &:focus
        outline none

.labrow
    margin-left: -42px
    margin-right: -18px

.new-material
    color ncss-white
    padding 0.4rem 2.5rem

.new-material-add-section
    text-align: right

.bottom-section
    text-align: center

.astericks
    color red
    
.secondary-button
    background  ncss-accent
    padding 0.4rem 2.5rem
    // float right 
    border 1px solid ncss-accent
    margin-right 10px
    color ncss-white

.secondary-button-addlocation
    background: ncss-grey
    padding 0.4rem 2.5rem
    border 1px solid ncss-grey
    margin-right 10px
    color ncss-white


.button-width-adjust
    width 8rem    

.progresstracker-next-button
    background  ncss-accent
    padding: 0.4rem 2.5rem;
    float right 
    border: 1px solid ncss-accent;
    margin-right: 10px;
    color ncss-white    

.progresstracker-prevoius-button
    background  ncss-accent
    padding: 0.4rem 2.5rem;
    float left 
    border: 1px solid ncss-accent;
    margin-right: 10px;
    color ncss-white      

.popup-bottom
    float right

.no-button
    padding: 0.4rem 1.5rem;
    border: 1px solid ncss-grey;
    margin-right: 10px;
    color ncss-white

.submit-button
    background ncss-grey-button
    padding: 0.4rem 2.5rem;
    color ncss-grey
    margin-right: 10px;
    border: 1px solid ncss-grey

.submit-button-enable
    background ncss-black
    padding: 0.4rem 2.5rem;
    color ncss-white
    margin-right: 10px;
    border: 1px solid black

.trf-button-selected:focus
  outline:0  

.trf-icon-brand-display 
    display inline-flex
    display -ms-inline-flexbox
    vertical-align middle

.nike-icon
    font-size 3.2rem

.converse-icon
    font-size 1.4rem
    

.hurley-icon
    font-size 2rem

.icon-align-center    
    align-self center

.radio-sub-info
    font-size 13px

.inline-flex-display
    display -moz-inline-flex
    display -ms-inline-flex
    display inline-flex

.mr3-display-inline
    margin-right 3rem

.flex-head-center
    align-self: center

.testpackage-font-adjust
    font-size:  16px !important

.grid-filter-textbox
    border: 4px solid #3498db;
    width: 100%;
    height: 3rem;
    padding: 15px;
    color: black;
    font-size: 16px;
    font-weight: bold;

.grid-filter-p-right-10
    padding-right 10px    

.p2-top-sm
    padding-top 8px

.p2-bottom-sm
    padding-bottom 8px

.p2-left-sm 
    padding-left 8px

.p4-top-sm
    padding-top 16px

.p4-bottom-sm
    padding-bottom 16px

.pl0-sm
    padding-left 0px    

.trf-data-text
    font-size: 16px
    font-weight: 700


.mat-data-text
    font-size: 20px;
    font-weight: 700;
    color: #000;
    padding: 0px 10px;
    background: #ddd;
    background: #cfcfcf;

.header-text-mat
    font-size: 16px
    padding-left: 0px

.trf-data-text-location
    font-size: 20px
    padding-left: 0
   
.ncss-textarea-container 
      font-size: 16px
      font-weight: 700

.trf-extra-top-margin
    margin-top 1rem

.text-left 
    text-align left !important    

.review-icon
    font-size 1.2rem

.review-icon-nike 
    font-size 2.5rem   

.error-text    
    color: ncss-error
    text-align: center
    font-weight: 550

.material-error-section
    border: 3px solid #f7f7f7  
    padding: 10px  

.error-text-material 
    color: ncss-error   

.error-text-not    
    color: #707070
    // text-align: center
    font-weight: bold
    padding-left: 0px;

.error-text-not-ink    
    color: #707070
    // text-align: center
    font-weight: bold
    padding-left: 0px;

.error-hide
    display none 
.popover-trfsection
    border: 1px solid lightgray;
    display: grid;
    padding:20px
    margin: 0.2rem 4.5rem;
    width: 69%;
    background-color: white
    box-shadow: 2px 2px #888888;

.react-tiny-popover-container
    z-index 100

.popover-new-materialsection 
    border: 2px solid lightgray;
    display: grid;
    padding:10px
    margin: 0.2rem 6.2rem;
    width: 80%;
    background-color: white
    box-shadow: -2px -2px 2px #888888;   
    
.popover-button
    position: absolute;
    right: 0;
    margin-right: 10rem;
    background: transparent;
    color:#FF4500;

.popover-button-new-materialsection
    position: absolute;
    right: 0;
    margin-right: 4rem;
    background: transparent;
    color:#FF4500;

.popover-button-licensee-sec
    position: absolute;
    right: 0;
    margin-right: 14rem;
    background: transparent;
    color:#FF4500;

.color-trf
    background:transparent;
    padding:0px
    i
        color:#FF4500;
        font-size:14px;
   
.prevent-scroll
    position: fixed
    padding: 5px 60px
    z-index: 13
    width: 100%
    margin: 0px
    overflow hidden

.top-scroll-fix
    top 44px
    background: #f7f7f7    

.bottom-scroll-fix
    bottom 61px

.clear-margin
    margin-bottom: 0 !important

.clear-button
    font-size: 15px;
    background-color: #fff;
    color: #0078b9 !important;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: bold !important;
    /* border-radius: 5px; */
    /* text-decoration: underline; */
    border: 1px solid #000;

.trf-review
    margin-bottom: 88px

.wrap 
  display: block;
  max-width: 300px;

.material-type-section
    
    padding: 10px 0px
    margin -1px
    box-shadow: 5px 5px 5px #b1acac;
    width:98%
    padding-top: 0px
    border: 3px solid rgba(0,0,0,.4);

.additional-test-mandatory
    background-color: #f7f7f7 
    padding: 5px 8px 
    

.additional-test-mandatory-message
    background-color: #f7f7f7 

.top-border
    border-top: 2px solid rgba(0,0,0,.4);

.variant-box
    width 100%
    border: 5px solid grey
    position: relative
    display: table
    padding: 5px
    margin: 0px 1px 6px 1px
    border-radius: 10px
    background #dedede
    color #000000
    
.small-text
    font-size: 15px !important

.in-line
    display: inline    

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
    .prevent-scroll
        position: fixed
        padding: 0px 60px
        z-index: 11
        width: 100%
        margin: 0px
    .progress-tracker-align 
        margin-top: 95px
        margin-bottom: 103px !important

.print-area
    margin-left 50px
    margin-right 50px
    padding-top 50px


.header-brand-pdf
    left 1rem
    top 1 rem
    margin-bottom 2 rem 
    font-size: 38px;
    font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;

.disclaimer
    background #dddddd
    padding 10px 
    border 2px solid black
.disclaimerPDF
    background #7EA6E0;
    padding 10px 
    border 1px solid black

.disclaimer
    h5
      font-weight  bold

.pdf-header
    background #dddddd
    border 2px solid black
    .menu-logo
        padding-left 5px
        padding-right 5px

.trf-content
    border 2px solid black
    .p2-sm, .pl0-sm
        font-size 15px

.menu-logo-nike
    margin-left 67%
    padding-left 5px
    padding-right 5px
    font-size: 22px;
    vertical-align: baseline;

.free-text
    border 1px solid #dddddd
    padding: 12px
    padding-top: 20px;

.supplier-info
     padding: 20px

.space
    padding-left: 30px !important

.qrf-content
    padding: 8px
    border 2px 
    .p2-sm, .pl0-sm
        font-size 15px

.ink-text
    padding-left: 0px;

.ink-label
    padding-left: 0px 
.supplier-info-container
    padding-left: 20px
    padding-right:20px
    padding-top: 0px
    padding-bottom: 8px 
.vertical-top
    vertical-align:top;
.mt-10
    margin-top:10px;
.mt-2
    margin-top:2px;
.trf-modal-width
   min-width:96% !important
.trf-modal-scroll 
    overflow-x: hidden;
    max-height: 500px;
.download-btn    
    float: right;
    margin-top: 6px;
    margin-right: 0px;
.trf-modal-container
    top: 49% !important;
    left: 50% !important;
    height: auto;
.trf-btn-padding
    padding: 0px 39px 0px 0px;
.preview-title
    margin-left: -7px;
.preview-logo
    padding-left: -67% !important;
.trf-layout-hide
    display: none
.hide-button	
    display none	
.font-size-n	
    font-size: 10px;	
.ink-sec-style	
    font-size: 10px;
.ink-inside-div
    padding-left: 0px;	
.mt-n-9	
    margin-top: -9px;   
.text-center
    text-align: center !important
.mt-6
    margin-top: -4px;
.country-padding
    padding-left: 20px !important;  
.div-block
    display:block
.div-none
    display:none
.sub-variant-div
    margin-left: 36px;
.ml-7
    margin-left: 7px; 
.ml-neg
   margin-left: -2px;
.ml-pos
   margin-left: 2px;
.ml-neg4
   margin-left: -4px;
.ml-neg3
   margin-left: -3px;
.ml-pos1
   margin-left: 1px;
.material-type
   padding: 0px 1px 0px 1px;
.m1-l4
  margin-left: -4px
.m1-l8
  margin-left: 8px
.m1-280
  margin-left: 280px
.ml-73
  margin-top: -73px
.rsl-div
 height: 36%;
.progresstracker-clear-button
 background: #3498db;
 border: 1px solid #3498db;
 margin-right: 10px;
 color: #fff;
.jewelry-clear-btn
 float: right;
 margin-right: 116px;
.addmore-error
 color: red !important
.h-20
 height:20px
.ml-7
 margin-left: -7px;
.mr-7
 margin-right: 7px;
.ml-6
 margin-left: 6px;

