.TestDetails
    margin-bottom: 88px 
    .test-detail-heading
        font-size 2rem
    .test-inline
        display inline
    .test-padding
        padding-left: 101px;
    .detail
        margin 0.8rem
    .m0-bottom-sm
        margin-bottom 10px
    .collapsible
        padding-bottom 10px
    .color-rd
        color #FE0000
    .color-or
        color #ea723f
    .color-bl
        color #000000
    .icon
        padding 1px 5px
        border-radius 50%
        position relative
        top 10px
        left 7%
        display inline-block
    .big-button-color
        border 1px solid #e5e5e5
        font-size: 17px;
        top: -5px;
        left: 1%;
        background: #FE0000;
    .success-section
        padding 0.5rem 1.5rem
        background #F5F5F5
    .success-font
        font-size 1.3rem
    .download-print-section
        font-size 14px
        background #f7f7f7
        text-align right
        font-weight bold
        float right
        padding-right 17px
        span
            i
                color #ccc
                padding-right 5px 
                font-size 30px
    .download-print-button
        background ncss-white
        font-weight bold
        padding 0
        letter-spacing 0
        font-family unset
        &:focus
            outline none
    .contact-section
        font-size 16px
        margin 15px 0 0 0
        font-weight bold
    .ncss_collapsible
        background: #eeeeee
    .Collapsible__contentInner .description
        color #000000
        padding-bottom 5px
    .Collapsible__trigger
        cursor pointer
    .collapse-p0
        padding-left 0
        font-size 1.5rem
    .ag-theme-balham
        .ag-header
            padding 1px 0
    .collapse-p0 .ncss-col-sm-1
        font-size 25px
    .popover-section
        border: 2px solid lightgray;
        display: grid;
        margin: 0.2rem 4.5rem;
        width: 69%;
        height: 70px
    .button-hover
        &:hover
            background: #ccebff
    .hide-button
        display none


    .popover-trfsection
        border 1px solid lightgray
        display grid
        padding 20px
        margin 0.2rem 4.5rem
        width 72%
        background-color white
        box-shadow  2px 2px #888888
   
   .popover-section
        .button-hover
            color: #0078b9 !important;
            font-family: "Open Sans Condensed", sans-serif;
            font-weight: bold !important;
            border: 1px solid #ddd !important;
            text-decoration: underline;
            padding: 17px;
            font-size: 16px;

.TestDetails 
    .download-print-button
        background: #f7f7f7

    .button-test
        color:white
        font-size: inherit 
        text-transform: uppercase
        font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif
        font-weight: 400
        letter-spacing: .5px
        border-radius: 10px

    .button-size
        width: 170px;
        height: 60px;

    .bg-color-grey
        background #707070
    .bg-color-or
        background #ea723f
    .bg-color-bl
        background #0078b9;
    
    .frf-comments-padding
        padding-left: 15px;
    .qrf-comments-padding
        padding-left: 25px;
    .ag-body-viewport.ag-layout-normal    
        // overflow-x: visible;
        // overflow-y: visible;
        //height: 250px;
        overflow-y: scroll; 

    .download_button 
         color: #333;
         padding-right: 5px;
         font-size: 25px;
         padding: 0px 2px;
         margin-left: 10px;
     .download_button:hover 
         background-color: #ccc;
         color: white;
         cursor: pointer; 
     .frf-attachment-align
         padding-left: 15px;
         vertical-align: top;

.pdf_table.testResults,
.pdf_table.testResults th, .pdf_table.testResults tr, .pdf_table.testResults tbody tr, 
.pdf_table.testResults td
   
    padding:0px;

.pdf_table
    width:100%;
    color:black;
    border:1px solid black;
    background-clip: padding-box;

    tr.break> td
        padding-bottom:20px;
    th,td
        text-align: left;
        overflow-wrap: break-word;
        white-space: pre-line;
        font-size : 10px; 
        background-clip: padding-box; 
        margin:0px;
        border-collapse: collapse;
    td
        padding: 3px;
    thead,th
        width:100%;
    .break_class td
        padding:10px

     .grey-background
        text-align: left;
        background: #e5e5e5;
        text-transform: uppercase;
        font-size: 10px; 
        z-index : -1;

.pdf_table_testResultbyclass.testResults,
.pdf_table_testResultbyclass.testResults th, .pdf_table_testResultbyclass.testResults tr, .pdf_table_testResultbyclass.testResults tbody tr, 
.pdf_table_testResultbyclass.testResults td
   
    padding:0px;

.pdf_table_testResultbyclass
    width:100%;
    color:black;
    border:1px solid black;
    background-clip: padding-box;
    tr.break> td
        padding-bottom:20px;
    th,td
        text-align: left;
        overflow-wrap: break-word;
        white-space: pre-line;
        font-size : 10px; 
        background-clip: padding-box; 
        margin:0px;
        border-collapse: collapse;
    td
        padding: 3px; 
    
    .break_class td
        padding:10px

     .grey-background
        text-align: left;
        background: #e5e5e5;
        text-transform: uppercase;
        font-size: 10px;
        z-index : -1;

.pdf_table_chemicalreport.testResults,
.pdf_table_chemicalreport.testResults th, .pdf_table_chemicalreport.testResults tr, .pdf_table_chemicalreport.testResults tbody tr, 
.pdf_table_chemicalreport.testResults td
   
    padding:0px;

.pdf_table_chemicalreport
    width:100%;
    color:black;
    border:1px solid black;

    td:first-child
        font-weight: bold;

    tr.break> td
        padding-bottom:20px;
    th,td
        text-align: left;
        overflow-wrap: break-word;
        white-space: pre-line;
        font-size : 10px; 
        background-clip: padding-box; 
        margin:0px;
        border-collapse: collapse;

    td
        padding-left:3px; 
    
    .break_class td
        padding:10px

     .grey-background
        text-align: left;
        background: #e5e5e5;
        text-transform: uppercase;
        font-size: 10px;
        z-index : -1;

    .grey-background_chth
        text-align: left;
        background: #cdcccc
        text-transform: uppercase;
        font-size: 10px;
        z-index : -1;
        border-top: 1px solid black;
        
.testDetailsPDF
    position: absolute;
    left: -9999em;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 10px;

    @font-face {
        font-family: "DejaVu Sans";
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
    }

.test_details
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 10px;
    .header
        position: absolute;
        top: 0;
        left:0
        display: inline-block;
        width:100%

    .header-brand-pdf-tag
        margin-left:6%

    .header-brand-pdf
        font-size: 17px;
        text-transform:upperCase

    .pdf_header
        display: table;
        width: 100%;
        vertical-align: top;

        span 
            display: table-cell;
            padding: 0;
            

        .g72-converse   
            margin-bottom: 20px;
            margin-right: 0;
            padding: 0;
            margin: 0;

        .g72-swoosh,.g72-hurley
                font-size: 26px;
                display: inherit;

        .g72-jordan
            font-size: 41px;
            padding-left: 40px;

    .pdf_header i,.footer *
        color: #707070;

    .footer *
        font-size: 12px;

    .hurley_span
        text-align: right;
        width: 100%;
        float: left;


.remove-border
    border none !important

.add-border-top
    border-top solid 1px black 

.ico
    display: block;
    height: 40px;  /*height of icon */
    width: 40px;  /*width of icon */
    position: relative;
    left: 0;
.ico.g72-snkrs
    top: -50px;
        
.ico.g72-converse  
    top: -20px;  
.ico.g72-hurley 
    top: -20px;  

.adjust-icon
    position relative

.adjust-icon.g72-swoosh
    bottom 16px

.adjust-icon.g72-jordan
    bottom -3px

.adjust-icon.g72-hurley
    bottom -1px

.vertical-top
    vertical-align: top
    
.bg-color-blue
    background: #99ccff;
    font-size: 10px;
    text-align: left;
    z-index: -1;
    border-top: 1px solid black;

.font-italic
     font-style: italic

.float-left
    float: right  

.padding-18
    padding-left: 18px;   

.padding-70
    padding-left: 70px; 
    text-align: right;

.padding-90
    padding-left: 90px; 
    text-align: left;    

.testPackage th, .testPackage tr, .testPackage tbody tr .testPackage tbody td
    border-spacing:0px;
    
.kidFail
    border-bottom: 1px solid black !important;

.add-border
    border 1px solid black;
    border-spacing:0px;

.header-name-style
        font-size : 10px;
        font-weight : bold;
.add-new-border-style
    border-right solid 1px  #000 !important
    border-top solid 1px #000 !important
    border-left solid 1px  #000 !important
    border-bottom solid 1px  #000 !important
    margin-bottom -2px
    
.inner-table-display
    color white

.grey-background-groupsum
    background: #e5e5e5;
    font-size: 10px;
    text-align: left;
    z-index: -1;
    
.row
    display: table-row;
    border-spacing: 0px;  
    border-collapse: collapse;

.cell 
    text-align: left;    
    display: table-cell; 
    width: 255px;
    border-top: 1px solid black;
    border-left: 1px solid black;      

.cell:first-child
    border-left:none;

.cell_ch
    text-align: left;    
    display: table-cell;
    border-top: 1px solid black;
    border-left: 1px solid black;
.cell_ch:first-child
    border-left:none;
.cell_lab_o
    text-align: left;    
    display: table-cell;
    border-top: 1px solid black;
    border-left: 1px solid black;
.table-align
    vertical-align: top;
.td-margin-left
    margin-left: -10px     
.padding-right-submitter
    padding-right:0px    

.padding-pageNo
    padding-left:56px;

.padding-logo
    padding-left:37px;

.footer-margin
    margin-left:-6px;
.mt-9
    margin-top: -9px;
.pb-1
    padding-bottom: 1px;  
.margin-lab
    margin-left: 20px;  
.margin-lab
    margin-left: 20px;
.align-lab
    vertical-align:top;
.hg-lab
    height:30px;
.mt-5
    margin-top: 5px;
    line-height: 200%;
.mt-6
    margin-top: -6px;
.txta-r
    text-align: right;
.ws-n
    white-space: normal;
.txta-j
    text-align: justify;
.ml-8
    margin-left: 8px;
.cs-n-tstpck
    border-spacing:0;
    border-collapse: collapse;
    border-left: 1px solid black;
.cs-n-tstpck:first-child
    border-left: none;
.cs-n
    border-spacing:0;
    border-collapse: collapse;
.cs-n:first-child
    border-left: none;
.tbl-cl 
    width: 68%;
    border: 1px solid black;
.th-cl
    position: relative;
    width: 255px;
    height: inherit;
.tbody-cl div
    font-size: 10px;
    margin: 0px;
    text-align: justify;
.th-cl-sum 
    position: relative;
    width: inherit;
    height:inherit;
.ml-n-8
    margin-left: -8px;
.ml-n-10
    margin-left: -17px;
.padding-3
    padding:3px;
.pd-l-3
    padding-left: 3px;
.billing-text-box 
    border: 1px solid #000;
    padding: 12px;
.billing-text-box-sm
    text-align: left;    
    display: table-cell; 
    width: 200px;
    border-top: 1px solid black;
    border-left: 1px solid black; 
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    height:20px  
.header-background
        text-align: left;
        background: #7EA6E0;
        text-transform: uppercase;
        font-size: 10px;
        z-index : -1;  
.ml-10
    margin-left: 11px; 
.span-expir
    height:'16px';
.ml-logo
    margin-left:-12px !important
.ml-expiry
    margin-left:-80px !important
.ml-logo180
    margin-left:-180px;
.ml-expiry10
    margin-left:-10px
.ml-40
    margin-left:40px
.mt-1
    margin-top: 1px;
.border-t
    border-top 1px solid black    
.pl-10
    padding-left 10px    